import { useState, useEffect } from "react";
import { useMatch } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import axios from "axios";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function TeamSingle() {
  const [team, setTeam] = useState({});
  const singleTeam = useMatch(WEB_ROUTES.TEAM + "/:team");
  const [loading, setLoading] = useState({});
  const [page, setPage] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "team"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });

        //Get team
        axios
          .get(CONTENT_ROUTES.TEAM("?slug=" + singleTeam?.params?.team))
          .then((res) => {
            let teamResponse = res.data?.length ? res.data[0] : {};
            setTeam(teamResponse);

            //Get team media
            let media = [];

            res.data?.map((member, i) => {
              if (member?.featured_media)
                axios
                  .get(CONTENT_ROUTES.MEDIA_BY_ID(member?.featured_media))
                  .then((res) => {
                    media[i] = res.data;
                    setTeamMedia((prevState) => [...prevState, media[i]]);
                  });
              return member?.featured_media;
            });
          })
          .catch((err) => {})
          .finally(() => {
            setLoading(false);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, [singleTeam]);

  return (
    <>
      <Helmet>
        <title>{team?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(team?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section>
          <div className="container mx-auto ">
            <div className="mx-auto md:flex">
              <div className="md:w-4/12 w-full">
                <img
                  src={
                    teamMedia?.find(
                      (media) => media.id === team?.featured_media
                    )?.source_url
                  }
                  alt={team?.title?.rendered}
                  className="object-cover"
                />

                <div className="w-full text-white p-16 md:pl-0 md:pt-10 md:px-16 px-8 md:block hidden">
                  <p className="text-[#ffa8bd]">Career Highlights</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: team?.acf?.team_member_highlights?.replace(
                        /<p.*?>/g,
                        "<p class='mb-4'>"
                      ),
                    }}
                  />
                </div>
              </div>
              <div className="md:w-8/12 w-full">
                <div className="w-full text-white bg-[#47188e] md:py-16 md:px-16 py-8 px-8">
                  <p className="font-medium text-[#8cccd1] mb-2">
                    Management Team
                  </p>
                  <h1 className="text-4xl font-medium">
                    {team?.title?.rendered}
                  </h1>
                  <h2 className="text-2xl mb-4">
                    {team?.acf?.team_member_designation}
                  </h2>
                  <p>
                    <span className="text-[#ffa8bd]">Qualifications:</span>{" "}
                    {team?.acf?.team_member_qualifications}
                  </p>
                </div>

                <div className="p-16 md:px-16 px-8 bg-white">
                  <p>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: team?.content?.rendered?.replace(
                          /<p.*?>/g,
                          "<p class='mb-5'>"
                        ),
                      }}
                    />
                  </p>
                </div>
              </div>
              <div className="w-full text-white p-16 md:pl-0 md:pt-10 md:px-16 px-8 block md:hidden">
                <p className="text-[#ffa8bd]">Career Highlights</p>
                <div
                  dangerouslySetInnerHTML={{
                    __html: team?.acf?.team_member_highlights?.replace(
                      /<p.*?>/g,
                      "<p class='mb-4'>"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
