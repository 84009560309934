import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { AppLoader } from "../components/app-loader";
import { WEB_ROUTES } from "../routes/web-routes";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Funds() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [funds, setFunds] = useState([]);
  const [fundsMedia, setFundsMedia] = useState([]);
  const [selectedTab, setSelectedTab] = useState(0);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "funds"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    //Get funds
    axios
      .get(CONTENT_ROUTES.FUNDS("?order_by=menu_order&order=asc&per_page=100"))
      .then((res) => {
        setFunds(res.data);

        //Get projects media
        let media = [];

        res.data?.map((fund, i) => {
          if (fund?.featured_media)
            axios
              .get(CONTENT_ROUTES.MEDIA_BY_ID(fund?.featured_media))
              .then((res) => {
                media[i] = res.data;
                setFundsMedia((prevState) => [...prevState, media[i]]);
              });
          return fund?.featured_media;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section>
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-2/5 w-full md:py-60 py-8 md:px-16 px-8 bg-[#47188e]">
                <span className="font-medium text-[#ffa8bd]">
                  For Investors
                </span>
                <h1 className="pt-2 text-4xl font-medium text-white">
                  {page?.title?.rendered}
                </h1>
              </div>
              <div
                className="md:w-3/5 w-full bg-white md:pt-20 pt-96 bg-no-repeat bg-center bg-cover"
                style={{
                  backgroundImage: `url(${
                    pageMedia?.find(
                      (media) => media?.id === page?.featured_media
                    )?.source_url
                  })`,
                }}
              ></div>
            </div>
          </div>
        </section>
        <section>
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-2/5 w-full"></div>
              <div className="md:w-3/5 w-full bg-white py-10 md:py-16 md:px-16 px-8 bg-no-repeat bg-center bg-cover">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered,
                  }}
                />
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            <div className="flex">
              <div className="w-2/5"></div>
              <div className="w-3/5 bg-white bg-no-repeat bg-center bg-cover">
                <div>
                  <nav className="flex space-x-1" aria-label="Tabs">
                    {funds?.map((fund, i) => (
                      <a
                        key={i}
                        href="#."
                        onClick={() => setSelectedTab(i)}
                        className={`${
                          selectedTab === i ? "bg-[#47188e]" : "bg-[#8c75b7]"
                        } md:px-10 px-4 py-3 font-medium text-white`}
                      >
                        {fund?.title?.rendered}
                      </a>
                    ))}
                  </nav>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container mx-auto">
            {funds?.map((fund, i) => (
              <div
                key={i}
                className={`${selectedTab === i ? "visible" : "hidden"}`}
              >
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Inception Year
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.inception_year}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Assets In Strategy
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.assets_in_strategy}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Target Fund Size
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.target_fund_size}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Minimum Size
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.minimum_size}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Open/Closed
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.open_closed}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Return Target
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.return_target}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-4 border-b border-white">
                    Sector Focus
                  </div>
                  <div className="w-3/5 text-left p-4 bg-[#47188e] border-b border-white">
                    {fund?.acf?.sector_focus}
                  </div>
                </div>
                <div className="flex text-white">
                  <div className="md:w-1/5"></div>
                  <div className="md:w-1/5 w-2/5 text-right p-8"></div>
                  <div className="w-3/5 text-left p-8 bg-[#47188e]"></div>
                </div>
                <div className="md:flex">
                  <div className="md:w-1/2 w-full py-10 md:px-16 px-8 bg-white">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: fund?.content?.rendered,
                      }}
                    />
                  </div>
                  <div className="md:w-1/2 w-full py-10 md:px-16 px-8 bg-[#bd4292] text-white">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: fund?.acf?.key_features,
                      }}
                    />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
