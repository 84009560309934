import { useContext } from "react";
import { OpenCloseContactContext } from "../contexts/contact";
import { NavLink } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import FormNewsletter from "../components/form-newsletter";

export default function Footer() {
  let { setOpenCloseContact } = useContext(OpenCloseContactContext);
  return (
    <footer className="bg-gray-950">
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="mx-auto container pb-16 pt-16 md:px-0 px-8">
        <NavLink to={WEB_ROUTES.HOME} className="inline-block">
          <img
            className="mb-5 h-12"
            src="/images/thirdway-logo-white.svg"
            alt="Third Way Investment Partners Logo"
          />
        </NavLink>
        <div className="md:grid md:grid-cols-6 md:gap-8">
          <div className="text-white col-span-2">
            <div className="max-w-md mb-4">
              Third Way Investment Partners is a subsidiary of the Third Way
              Investment Group, which is a growing specialist asset management
              group. The investment company is an independent and Black-owned
              multi-disciplined infrastructure boutique firm which has also
              partnered with internationally recognised expert service
              providers.
            </div>
            <a
              href="https://www.facebook.com/ThirdwayZA"
              target="_blank"
              rel="noreferrer"
              className="text-neutral-400 group-hover:text-white inline-block mr-3 float-left"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                enableBackground="new 0 0 100 100"
                height="512"
                viewBox="0 0 100 100"
                width="512"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g id="_x30_1._Facebook">
                  <path
                    id="Icon_11_"
                    d="m40.4 55.2c-.3 0-6.9 0-9.9 0-1.6 0-2.1-.6-2.1-2.1 0-4 0-8.1 0-12.1 0-1.6.6-2.1 2.1-2.1h9.9c0-.3 0-6.1 0-8.8 0-4 .7-7.8 2.7-11.3 2.1-3.6 5.1-6 8.9-7.4 2.5-.9 5-1.3 7.7-1.3h9.8c1.4 0 2 .6 2 2v11.4c0 1.4-.6 2-2 2-2.7 0-5.4 0-8.1.1-2.7 0-4.1 1.3-4.1 4.1-.1 3 0 5.9 0 9h11.6c1.6 0 2.2.6 2.2 2.2v12.1c0 1.6-.5 2.1-2.2 2.1-3.6 0-11.3 0-11.6 0v32.6c0 1.7-.5 2.3-2.3 2.3-4.2 0-8.3 0-12.5 0-1.5 0-2.1-.6-2.1-2.1 0-10.5 0-32.4 0-32.7z"
                  ></path>
                </g>
              </svg>
            </a>
            <a
              href="https://x.com/ThirdWayZA"
              target="_blank"
              rel="noreferrer"
              className="text-neutral-400 group-hover:text-white inline-block mr-3 float-left"
            >
              <svg
                className="h-4 w-4 my-1"
                fill="currentColor"
                enableBackground="new 0 0 1226.37 1226.37"
                viewBox="0 0 1226.37 1226.37"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="m727.348 519.284 446.727-519.284h-105.86l-387.893 450.887-309.809-450.887h-357.328l468.492 681.821-468.492 544.549h105.866l409.625-476.152 327.181 476.152h357.328l-485.863-707.086zm-144.998 168.544-47.468-67.894-377.686-540.24h162.604l304.797 435.991 47.468 67.894 396.2 566.721h-162.604l-323.311-462.446z"></path>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </a>
            <a
              href="https://www.linkedin.com/company/thirdwayza/"
              target="_blank"
              rel="noreferrer"
              className="text-neutral-400 group-hover:text-white inline-block float-left"
            >
              <svg
                className="h-6 w-6"
                fill="currentColor"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 448 512"
              >
                <path d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z" />
              </svg>
            </a>
            <div className="clear-both"></div>
          </div>
          <div className="mt-10 md:mt-0 col-span-1">
            <h3 className="font-medium text-neutral-400 text-lg">
              Quick Links
            </h3>
            <ul className="mt-6 space-y-4">
              <li>
                <NavLink
                  to={WEB_ROUTES.ABOUT}
                  className="text-white hover:text-neutral-400"
                >
                  About
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.FUNDS}
                  className="text-white hover:text-neutral-400"
                >
                  Funds
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.INVESTMENTS}
                  className="text-white hover:text-neutral-400"
                >
                  Investments
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.IMPACT}
                  className="text-white hover:text-neutral-400"
                >
                  Impact
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.NEWS}
                  className="text-white hover:text-neutral-400"
                >
                  News & Insights
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.CONTACT}
                  className="text-white hover:text-neutral-400"
                >
                  Contact Us
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mt-10 md:mt-0 col-span-1">
            <h3 className="font-medium text-neutral-400 text-lg">Policies</h3>
            <ul className="mt-6 space-y-4 text-white">
              <li>
                <NavLink
                  to={WEB_ROUTES.PRIVACY_POLICY}
                  className="text-white hover:text-neutral-400"
                >
                  Privacy policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.TERMS}
                  className="text-white hover:text-neutral-400"
                >
                  Terms of use
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.COPYRIGHT_STATEMENT}
                  className="text-white hover:text-neutral-400"
                >
                  Copyright Statement
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.COOKIE_POLICY}
                  className="text-white hover:text-neutral-400"
                >
                  Cookie Policy
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={WEB_ROUTES.CONFLICT_POLICY}
                  className="text-white hover:text-neutral-400"
                >
                  Conflict of Interest Policy
                </NavLink>
              </li>
            </ul>
          </div>

          <div className="mt-10 md:mt-0 col-span-2">
            <h3 className="mb-6 font-medium text-neutral-400 text-lg">
              Subscribe to our mailing list
            </h3>
            <FormNewsletter bgType={`light`} />
          </div>
        </div>
      </div>

      <div className="py-10 border-t border-white/10 text-neutral-400 md:px-0 px-8">
        <div className="mx-auto container md:space-x-4 space-x-0 mb-4">
          Third Way Investment Partners (Pty) Ltd. is licensed as an authorised
          financial services provider under the jurisdiction of the Financial
          Sector Conduct Authority (FSCA) in terms of the Financial Advisory and
          Intermediary Services Act, 2002 (Act No. 37 of 2002), License number
          48341.
        </div>

        <div className="mx-auto container md:space-x-4 space-x-0">
          <div className="md:float-left float-left mb-0 md:mt-0 mt-4">
            &copy; Copyright {new Date().getFullYear()}. All rights reserved.
            Third Way Investment Partners. Developed and managed by{" "}
            <a href="https://www.kaitomacreatives.co.za/" target="_blank">
              Kaitoma Collective
            </a>{" "}
            in collaboration with{" "}
            <a href="https://www.bluevinegroup.co.za/" target="_blank">
              Bluevine
            </a>
            .
          </div>

          <div className="clear-both"></div>
        </div>
      </div>
    </footer>
  );
}
