const CONTENT_API_URL = `https://www.thirdway.co.za/admin/wp-json/wp/v2`;

export const CONTENT_ROUTES = {
  SERVICES: function (params) {
    return `${CONTENT_API_URL}/service/${params}`;
  },
  TEAM: function (params) {
    return `${CONTENT_API_URL}/team-member/${params}`;
  },
  BANNERS: function (params) {
    return `${CONTENT_API_URL}/banner/${params}`;
  },
  PROJECTS: function (params) {
    return `${CONTENT_API_URL}/project/${params}`;
  },
  FUNDS: function (params) {
    return `${CONTENT_API_URL}/fund/${params}`;
  },
  MEDIA_BY_PAGE: function (id) {
    return `${CONTENT_API_URL}/media/?parent=${id}`;
  },
  MEDIA_BY_ID: function (id) {
    return `${CONTENT_API_URL}/media/${id}`;
  },
  POST_PAGE: function (type, slug) {
    return `${CONTENT_API_URL}${type}?slug=${slug}`;
  },
  POST_PAGE_BY_PARENT: function (type, parent_id) {
    return `${CONTENT_API_URL}${type}?parent=${parent_id}`;
  },
  FLEET: {
    ALL: `${CONTENT_API_URL}/fleet?per_page=100`,
    CATEGORIES: `${CONTENT_API_URL}/fleet_categories`,
    BY_CATEGORY: function (category_id) {
      return `${CONTENT_API_URL}/fleet?fleet_categories=${category_id}&per_page=100`;
    },
  },
  BRANCHES: {
    CATEGORIES: `${CONTENT_API_URL}/branches_categories`,
    BY_CATEGORY: function (category_id) {
      return `${CONTENT_API_URL}/branches?branches_categories=${category_id}&per_page=100`;
    },
    BY_SLUG: function (slug) {
      return `${CONTENT_API_URL}/branches?slug=${slug}`;
    },
  },
};
