import { useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
/* import NotFound from "pages/404"; */
import "./App.css";
import ScrollToTop from "./components/scroll-to-top";
import Home from "./pages/home";
import About from "./pages/about";
import Contact from "./pages/contact";
import Impact from "./pages/impact";
import Investments from "./pages/investments";
import Funds from "./pages/funds";
import Team from "./pages/team";
import TeamSingle from "./pages/team-single";
import News from "./pages/news";
import NewsSingle from "./pages/news-single";
import Newsletter from "./pages/newsletter";
import Careers from "./pages/careers";
import Page from "./pages/page";
import { OpenCloseContactContext } from "./contexts/contact";
import { LoaderContext } from "./contexts/loader";
import { WEB_ROUTES } from "./routes/web-routes";
import ReactGA from 'react-ga4';

function App() {

  ReactGA.initialize('G-WSGZ8Z71NL');

  const [openCloseContact, setOpenCloseContact] = useState(false);
  const [loading, setLoading] = useState(false);

  return (
    <LoaderContext.Provider value={{ loading, setLoading }}>
      <OpenCloseContactContext.Provider
        value={{ openCloseContact, setOpenCloseContact }}
      >
        <BrowserRouter>
          <ScrollToTop />
          <Routes>
            {/* PUBLIC ROUTES */}
            <Route index exact path={WEB_ROUTES.HOME} element={<Home />} />
            <Route path={WEB_ROUTES.ABOUT} element={<About />} />
            <Route path={WEB_ROUTES.IMPACT} element={<Impact />} />
            <Route path={WEB_ROUTES.INVESTMENTS} element={<Investments />} />
            <Route path={WEB_ROUTES.FUNDS} element={<Funds />} />
            <Route path={WEB_ROUTES.CONTACT} element={<Contact />} />
            <Route path={WEB_ROUTES.TEAM} element={<Team />} />
            <Route path={WEB_ROUTES.TEAM + `/:team`} element={<TeamSingle />} />
            <Route path={WEB_ROUTES.NEWS} element={<News />} />
            <Route path={WEB_ROUTES.NEWS + `/:news`} element={<NewsSingle />} />
            <Route path={WEB_ROUTES.NEWSLETTER} element={<Newsletter />} />
            <Route path={WEB_ROUTES.CAREERS} element={<Careers />} />

            {/* <Route exact path="/404" element={<NotFound />} /> */}
            <Route exact path="/:slug/*" element={<Page />} />
            <Route path="/*" element={<Home />} />
            {/* <Route path="*" element={<NotFound />} /> */}
          </Routes>
        </BrowserRouter>
      </OpenCloseContactContext.Provider>
    </LoaderContext.Provider>
  );
}

export default App;
