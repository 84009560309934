import { useState, useEffect } from "react";
import axios from "axios";
import { useMatch } from "react-router-dom";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Page() {
  const slug = useMatch("/:slug/*");
  const pageSlugs = slug.pathname?.split("/")?.filter((str) => str !== "");
  const pageSlug = pageSlugs[pageSlugs.length - 1];
  const [page, setPage] = useState({});
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", pageSlug + `&_embed`))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);
      })
      .catch((err) => {})
      .finally(() => {});
  }, [pageSlug]);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section>
          <div className="container mx-auto md:py-16 md:px-16 px-8 py-8 bg-[#47188e]">
            <div className="text-base">
              <p className="text-[#ffa8bd] mb-4 font-semibold">
                {slug?.params?.slug?.charAt(0)?.toUpperCase() +
                  slug?.params?.slug?.slice(1)}
              </p>
              <h1 className="text-4xl font-semibold text-white">
                {page?.title?.rendered}
              </h1>
            </div>
          </div>
        </section>
        <section className="md:pb-20">
          <div className="container mx-auto py-10 md:p-16 md:px-16 px-8 bg-white ">
            <div className="text-base leading-7">
              <div className="max-w-4xl">
                <div
                  dangerouslySetInnerHTML={{
                    __html: page?.content?.rendered?.replace(
                      /<p.*?>/g,
                      "<p class='mb-5'>"
                    ),
                  }}
                />
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
