import { Fragment, useEffect, useState } from "react";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import Header from "../components/header";
import Footer from "../components/footer";
import { NavLink } from "react-router-dom";
import { AppLoader } from "../components/app-loader";
import { WEB_ROUTES } from "../routes/web-routes";
import TeamItem from "../components/team-item";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function About() {
  const [page, setPage] = useState({});
  const [loading, setLoading] = useState({});
  const [pageMedia, setPageMedia] = useState([]);
  const [team, setTeam] = useState([]);
  const [teamMedia, setTeamMedia] = useState([]);
  let { getMetaSnippet } = useUtilities();

  useEffect(() => {
    setLoading(true);
    axios
      .get(CONTENT_ROUTES.POST_PAGE("/pages", "about-us"))
      .then((res) => {
        const pageResponse = res.data?.length ? res.data[0] : {};
        setPage(pageResponse);

        //Get page media
        axios
          .get(CONTENT_ROUTES.MEDIA_BY_PAGE(pageResponse?.id))
          .then((res) => {
            setPageMedia(res.data);
          });
      })
      .catch((err) => {})
      .finally(() => {});
  }, []);

  useEffect(() => {
    setLoading(true);

    //Get team
    axios
      .get(CONTENT_ROUTES.TEAM("?order_by=menu_order&order=asc&per_page=100"))
      .then((res) => {
        setTeam(res.data);

        //Get team media
        let media = [];

        res.data?.map((member, i) => {
          if (member?.acf?.team_member_thumbnail)
            axios
              .get(
                CONTENT_ROUTES.MEDIA_BY_ID(member?.acf?.team_member_thumbnail)
              )
              .then((res) => {
                media[i] = res.data;
                setTeamMedia((prevState) => [...prevState, media[i]]);
              });
          return member?.acf?.team_member_thumbnail;
        });
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title>{page?.title?.rendered + ` - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(page?.content?.rendered)}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section className="bg-white">
          <div
            className="container mx-auto bg-no-repeat bg-top md:bg-center md:bg-cover md:pt-80 bg-[length:auto_80%]"
            style={{
              backgroundImage: `url(
              ${
                pageMedia?.find((media) => media?.id === page?.featured_media)
                  ?.source_url
              }
            )`,
            }}
          >
            <div className="mx-auto md:flex">
              <div className="md:w-2/5 w-full md:py-10 md:px-16 px-8 text-white pt-10 pb-80">
                <div className="relative">
                  <span className="font-medium">Who we are and what we do</span>
                  <h1 className="pt-5 text-4xl font-medium">
                    {page?.title?.rendered}
                  </h1>
                </div>
              </div>
              <div className="md:w-3/5 w-full bg-white py-10 md:px-10 px-8">
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: page?.content?.rendered?.replace(
                        /<p.*?>/g,
                        "<p class='mb-5'>"
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="container mx-auto bg-[#47188e] text-white md:px-16 px-8 md:py-20 py-10 md:flex md:gap-x-16 ">
            <div
              className="md:mb-0 mb-5"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.sub_section?.sub_section_left_column,
              }}
            />
            <div
              dangerouslySetInnerHTML={{
                __html: page?.acf?.sub_section?.sub_section_right_column,
              }}
            />
          </div>
        </section>

        <section className="relative">
          <div className="container mx-auto text-white py-8 md:px-16 px-8">
            <div className="md:text-right">
              <span
                className="text-2xl md:mr-10 font-medium"
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.tagline?.tagline_title,
                }}
              />
              <NavLink
                to={page?.acf?.tagline?.tagline_cta?.url}
                className="flex-none bg-[#e86861] px-5 py-2 text-white inline-block md:mt-0 mt-4"
              >
                {page?.acf?.tagline?.tagline_cta?.title}{" "}
                <span aria-hidden="true">→</span>
              </NavLink>
            </div>
          </div>
        </section>

        <section className="relative md:pb-16">
          <div className="container mx-auto md:flex ">
            <div className="md:w-1/2 w-full">
              <div
                className="bg-white py-10 md:py-16 md:px-16 px-8"
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.diverse_block?.diverse_block_1,
                }}
              />
              <div
                className="text-white py-10 md:py-16 md:px-16 px-8"
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.diverse_block?.diverse_block_2,
                }}
              />
            </div>
            <div className="relative md:w-1/2 w-full">
              <img
                className="absolute md:top-10 top-8 left-10 z-10 md:w-16 w-10"
                src="/images/thirdway-icon.svg"
                alt="Third Way Investment Partners Icon"
              />
              <img
                src={
                  pageMedia?.find(
                    (media) =>
                      media.id === page?.acf?.diverse_block?.diverse_block_image
                  )?.source_url
                }
                alt={page.title?.rendered}
                className="md:absolute inset-0 h-full w-full object-cover"
              />
            </div>
          </div>
        </section>

        <section className="relative">
          <div className="container mx-auto bg-[#47188e] text-white md:px-16 px-8 md:py-20 py-10">
            <h3
              dangerouslySetInnerHTML={{
                __html: page?.acf?.lower_block?.lower_block_header,
              }}
            />
            <div className=" md:flex gap-x-16">
              <div
                className="md:mb-0 mb-5"
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.lower_block?.lower_block_column_1,
                }}
              />

              <div
                dangerouslySetInnerHTML={{
                  __html: page?.acf?.lower_block?.lower_block_column_2,
                }}
              />
            </div>
          </div>
        </section>

        <section className="bg-white">
          <div className="container mx-auto md:pt-20 pt-10 md:px-16 px-8">
            <h3 className="text-4xl font-medium text-[#270a51]">
              Management Team
            </h3>
            <div className="mt-10 mx-auto grid grid-cols-1 md:grid-cols-4 md:space-x-10">
              {team?.slice(0, 4).map((member, i) => (
                <Fragment key={i}>
                  <TeamItem
                    member={member}
                    memberMedia={teamMedia?.find(
                      (media) => media.id === member?.acf?.team_member_thumbnail
                    )}
                    type={"embed"}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </section>

        <section className="relative bg-white">
          <div className="container mx-auto md:py-20 py-10 md:px-16 px-8">
            <div
              className="max-w-2xl"
              dangerouslySetInnerHTML={{
                __html: page?.acf?.graduate_opportunities?.replace(
                  /<p.*?>/g,
                  "<p>"
                ),
              }}
            />
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
