import { useState, useEffect, useContext, Fragment } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { WEB_ROUTES } from "../routes/web-routes";
import { OpenCloseContactContext } from "../contexts/contact";
import {
  Dialog,
  DialogBackdrop,
  DialogPanel,
  DialogTitle,
} from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { CONTENT_ROUTES } from "../routes/content-routes";
import FormContact from "./form-contact";

export default function Header() {
  const location = useLocation();
  const [open, setOpen] = useState(false);

  return (
    <header className="bg-white relative">
      <nav
        className="container mx-auto flex items-center justify-between py-4 md:px-0 px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <NavLink to={WEB_ROUTES.HOME} className="inline-block w-48 h-auto">
            <span className="sr-only">Third Way Investment Partners Logo</span>
            <img
              src="/images/thirdway-logo.svg"
              alt="Third Way Investment Partners Logo"
            />
          </NavLink>
        </div>

        <div className="hidden lg:flex lg:gap-x-12">
          <NavLink to={WEB_ROUTES.HOME} className="leading-6 font-medium">
            Home
            <div className={``}></div>
            {WEB_ROUTES.HOME === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.ABOUT} className="leading-6 font-medium">
            About
            <div className={``}></div>
            {WEB_ROUTES.ABOUT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.FUNDS} className="leading-6 font-medium">
            Funds
            <div className={``}></div>
            {WEB_ROUTES.FUNDS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink
            to={WEB_ROUTES.INVESTMENTS}
            className="leading-6 font-medium"
          >
            Investments
            <div className={``}></div>
            {WEB_ROUTES.INVESTMENTS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.IMPACT} className="leading-6 font-medium">
            Impact
            <div className={``}></div>
            {WEB_ROUTES.IMPACT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.NEWS} className="leading-6 font-medium">
            News & Insights
            <div className={``}></div>
            {WEB_ROUTES.NEWS === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>

          <NavLink to={WEB_ROUTES.CONTACT} className="leading-6 font-medium">
            Contact Us
            <div className={``}></div>
            {WEB_ROUTES.CONTACT === location.pathname && (
              <div className={``}></div>
            )}
          </NavLink>
        </div>

        <div className="flex items-center gap-6">
          <div className="block lg:hidden">
            <label
              className="relative z-40 cursor-pointer px-3 py-6"
              htmlFor="mobile-menu"
            >
              <input className="peer hidden" type="checkbox" id="mobile-menu" />
              <div className="relative z-50 block h-[1px] w-7 bg-black bg-transparent content-[''] before:absolute before:top-[-0.35rem] before:z-50 before:block before:h-full before:w-full before:bg-black before:transition-all before:duration-200 before:ease-out before:content-[''] after:absolute after:right-0 after:bottom-[-0.35rem] after:block after:h-full after:w-full after:bg-black after:transition-all after:duration-200 after:ease-out after:content-[''] peer-checked:bg-transparent before:peer-checked:top-0 before:peer-checked:w-full before:peer-checked:rotate-45 before:peer-checked:transform after:peer-checked:bottom-0 after:peer-checked:w-full after:peer-checked:-rotate-45 after:peer-checked:transform"></div>
              <div className="fixed inset-0 z-40 hidden h-full w-full bg-black/50 backdrop-blur-sm peer-checked:block">
                &nbsp;
              </div>
              <div className="fixed top-0 right-0 z-40 h-full w-full translate-x-full overflow-y-auto overscroll-y-none transition duration-500 peer-checked:translate-x-0">
                <div className="float-right min-h-full w-[85%] bg-white px-10 py-6 shadow-2xl">
                  <NavLink className="inline-block" to={WEB_ROUTES.HOME}>
                    <img
                      className=" w-40 h-auto"
                      src="/images/thirdway-logo.svg"
                      alt="Third Way Investment Partners Logo"
                    />
                  </NavLink>
                  <menu className="mt-8 mb-8 flex flex-col space-y-4 text-heading">
                    <li className="whitespace-nowrap pb-1 font-semibold">
                      <NavLink to={WEB_ROUTES.HOME} className="nav-link-item">
                        Home
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        className="font-semibold nav-link-item drop-trigger"
                        to={WEB_ROUTES.ABOUT}
                      >
                        About
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        to={WEB_ROUTES.FUNDS}
                        className="font-semibold nav-link-item drop-trigger"
                      >
                        Funds
                      </NavLink>
                    </li>
                    <li className="whitespace-nowrap pb-1 font-semibold">
                      <NavLink
                        to={WEB_ROUTES.INVESTMENTS}
                        className="nav-link-item"
                      >
                        Investments
                      </NavLink>
                    </li>
                    <li className="whitespace-nowrap pb-1 font-semibold">
                      <NavLink to={WEB_ROUTES.IMPACT} className="nav-link-item">
                        Impact
                      </NavLink>
                    </li>
                    <li className="whitespace-nowrap pb-1 font-semibold">
                      <NavLink to={WEB_ROUTES.NEWS} className="nav-link-item">
                        News & Insights
                      </NavLink>
                    </li>
                    <li className="whitespace-nowrap pb-1 font-semibold">
                      <NavLink
                        to={WEB_ROUTES.CONTACT}
                        className="nav-link-item"
                      >
                        Contact Us
                      </NavLink>
                    </li>
                  </menu>
                </div>
              </div>
            </label>
          </div>
        </div>
      </nav>
    </header>
  );
}
