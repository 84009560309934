import FormNewsletter from "../components/form-newsletter";
import Header from "../components/header";
import Footer from "../components/footer";
import { Helmet } from "react-helmet";
import { useUtilities } from "../hooks/utilities";

export default function Newsletter() {
  let { getMetaSnippet } = useUtilities();

  return (
    <>
      <Helmet>
        <title>{`Sign up for our mailing list - Third Way`}</title>
        <meta
          name="description"
          content={getMetaSnippet(
            `You can sign up for our mailing list for all the latest Third Way Investment Partners news and related information by completing the subscription form.`
          )}
        />
      </Helmet>
      <Header />
      <main className="bg-[#270a51]">
        <section>
          <div className="container mx-auto">
            <div className="md:flex">
              <div className="md:w-2/5 w-full md:py-60 py-8 md:px-16 px-8 bg-[#47188e]">
                <span className="font-medium text-[#ffa8bd]">Sign up</span>
                <h1 className="pt-2 text-4xl font-medium text-white">
                  Mailing list
                </h1>
              </div>
              <div className="md:w-3/5 w-full bg-white p-20">
                <article className="w-full mb-4">
                  <p className="text-2xl">
                    Join our mailing list today and stay updated with the latest
                    news.
                  </p>
                  <p className="mt-4 text-sm text-gray-500">
                    You can unsubscribe from our mailing list at any time using
                    the link in our emails. Learn about how we process your
                    personal data in our{" "}
                    <a className="hover:underline" href="/legal/privacy-policy">
                      privacy&nbsp;policy
                    </a>
                    .
                  </p>
                </article>
                <FormNewsletter bgType={`light`} />
                <p className="mt-4 text-sm text-gray-500">
                  This form is protected by reCAPTCHA. Google's{" "}
                  <a
                    href="https://policies.google.com/privacy?hl=en-GB"
                    target="_blank"
                    className="hover:underline"
                  >
                    Privacy Policy
                  </a>{" "}
                  and{" "}
                  <a
                    href="https://policies.google.com/terms?hl=en-GB"
                    target="_blank"
                    className="hover:underline"
                  >
                    Terms of Service
                  </a>{" "}
                  apply.
                </p>
              </div>
            </div>
          </div>
        </section>
      </main>
      <Footer />
    </>
  );
}
